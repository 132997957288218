.exhut-widget ul li {
  margin-top: 5px;
}

.exhut-widget-title {
  display: flex;
}

.exhut-widget-mobile-logo-panel {
  display: flex;
  margin: 20px 0px;
  justify-content: space-evenly;
}

.exhut-widget-title-img {
  border-radius: 30px;
  cursor: pointer;
  width: 100px;
}

.exhut-widget-title-right-panel {
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.exhut-widget-title-right-panel-title {
  margin: 10px 0;
}

.exhut-widget-features-panel {
  color: white;
}

.exhut-widget-features-panel ul {
  font-size: small;
  list-style: disc;
}

.exhut-widget-features-panel-title {
  color: yellow;
}
