#header {
  background: linear-gradient(
    90deg,
    rgba(0, 107, 136, 1) 25%,
    rgba(38, 0, 51, 1) 100%
  );
  border-bottom: 1px solid #e6e6e6;
  padding: 27px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header > div {
  /** width: 100%; **/
  margin-left: 20px;
  padding: 0 20px;
}
/** Navigation **/
#navigation {
  display: inline-block;
  list-style: none;
  line-height: 80px;
  margin: 0 40px;
}
/** Logo **/
#header .logo {
  float: left;
  display: block;
  font: 20px/30px "Play";
  text-decoration: none;
  color: white;
  font-family: sans-serif;
  line-height: 80px;
  padding: 10px;
}

#navigation ul li a:hover {
  background: #2c3e50;
}

#navigation li ul {
  display: none;
}

#navigation li:hover > ul {
  display: block;
  position: absolute;
  z-index: 999;
}

#navigation ul li {
  display: block;
  position: relative;
  float: left;
}

#navigation li:hover li {
  float: none;
}

#navigation li:hover li a:hover {
  background: #cccbcb;
}

#navigation li ul li {
  border-top: 0;
}

#navigation ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
#navigation li {
  float: left;
  width: 100px;
  text-align: center;
}
#navigation li a {
  color: white;
  font-size: 15px;
  line-height: 30px;
  text-decoration: none;
}
#navigation li a:hover {
  color: yellow;
}
#navigation li.active a {
  color: yellow;
}
