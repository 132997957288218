.greeting {
  display: flex;
  min-height: 60px;
}

ul {
  list-style: none;
  padding-inline-start: 0px;
}
.greeting h1 {
  animation: fadein 2.5s;
  -webkit-animation: fadein 2.5s;
  margin-right: 5px;
  color: #277cff;
  font-weight: bold;
}
