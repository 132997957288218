:root {
  --primary: #7f1bd0;
  --primary-light: #be83ee;
  --primary-extra-light: #ddb3ff;
  --fnx-black: #020202;
  --fnx-dark-grey: #7a7a7a;
  --fnx-green: #02a802;
  --fnx-light-green: #65f34b;
  --fnx-light-grey0: #969696;
  --fnx-light-grey3: #e2e2e2;
  --fnx-light-grey5: #ececec;
  --fnx-orange: #e4a701;
  --fnx-red: #ff0000;
  --fnx-light-red: #ff7171;
  --fnx-total-green: #06b191;
  --fnx-white: #ffffff;
  --fnx-blue: #2196f3;
  --fnx-dark-blue: blue;
  --fnx-ramar-blue: #66baff;
  --fnx-yellow: #eeff00;
}

body {
  background-color: #fff;
  margin: 0;
}

/*---------------------TABLE-----------------------------*/
table {
  border-bottom: 1px solid black;
  line-height: 2em;
}

th,
td {
  border-bottom: 1px solid black;
  text-align: center;
}

tr td {
  text-align: center;
}

/*------------------------------ CONTENTS ------------------------------*/
#contents {
  min-height: 510px;
  margin: 10px;
  padding: 1em;
}

.section-header-title-section {
  margin: 20px 0;
}

.section-header-title {
  color: #2c2c2c;
  font-size: 24px;
  font-weight: normal;
  line-height: 24px;
  margin: 0 0 12px;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

.iconSize {
  width: 30px;
  height: 30px;
}

.text-xl {
  font-weight: bold;
  font-size: large;
}

.text-xxl {
  font-weight: bold;
  font-size: larger;
}

.text-smaller {
  font-size: smaller;
}

.divCenterAlign {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.divColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.divAlignOnly {
  display: flex;
  align-items: center;
}

.divSpread {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.my-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.line-30 {
  line-height: 30px;
}
